const lottieHouse = {
  warm: '_MCF-House-Heat.json',
  cold: '_MCF-House-Cool.json',
}

const lottieWeather = {
  rain: {
    keywords: ['rain', 'showers', 'thunder', 'thunderstorms'],
    file: '_MCF-weather-rain.json'
  },
  lightning: {
    keywords: [],
    file: '_MCF-weather-lightning.json'
  }
}

const skyLayer = {
  clear: ['sunny', 'clear'],
  cloudy: ['cloudy', 'showers', 'thunderstorms'],
  partly: ['mostly', 'partly', 'partly cloudy', 'rainy']
}

class Weather {
  constructor() {
    this.setTracker();
  }

  get weather() {
    return this.data;
  }

  setTracker(id = 'lv-data-tracker') {
    this.tracker = document.createElement('div');
    this.tracker.setAttribute('id', id);
    this.tracker.style.visibility = 'hidden';
    document.body.append(this.tracker);
  }

  async getForecast(callback) {
    return await fetch(`https://api.weather.gov/gridpoints/FFC/43,87/forecast`)
      .then(response => response.json())
      .then(res => {

        let data_y = res.properties.periods.filter(p => p.isDaytime).map(p => p.temperature);
        const today = res.properties.periods[0];

        this.data = {
          yData: data_y,
          points: res.properties.periods,
          slope: this.#slope(data_y, Object.keys(data_y)),
          avg: data_y.reduce((p,c) => p + c, 0) / data_y.length,
          forecast: {
            short: today.shortForecast,
            temp: today.temperature,
            wind: today.windSpeed
          }
        };

        this.tracker.setAttribute('data-loaded', true);
        callback();
      });
  }

  setWeather(forecast) {
    forecast = forecast.toLowerCase();

    if (skyLayer.clear.some(v => forecast.includes(v))) {
      return '/images/MCF-clear-sunny.webp'; // cloudy file name
    }

    if (skyLayer.partly.some(v => forecast.includes(v))) {
      return '/images/MCF-cloudy-partly.webp'; // partly file name
    }

    if (skyLayer.cloudy.some(v => forecast.includes(v))) {
      return '/images/MCF-cloudy-none.webp'; // cloudy file name
    }
  }

  // Calculate the mean of an array
  #mean(data) {
    return data.reduce((a,b) => a + b, 0) / data.length;
  }

  // Calculate the slope of datapoints
  #slope(data_y, data_x) {
    const xmean = this.#mean(data_x);
    const ymean = this.#mean(data_y);
    const n = data_x.length;

    let num = 0;
    let den = 0;

    for(let i = 0; i < n; i++) {
      num += (data_x[i] - xmean) * (data_y[i] - ymean);
      den += Math.pow(data_x[i] - xmean, 2);
    }

    return Number(num / den).toFixed(20);
  }
}

const observer = new MutationObserver(function(mutations_list) {
	mutations_list.forEach(function(mutation) {
		mutation.addedNodes.forEach(function(added_node) {
			if(added_node.id == 'lv-data-tracker') {
				console.log('#child has been added');
				observer.disconnect();
			}
		});
	});
});

const forecast = new Weather();

forecast.getForecast(() => {
  let sky = forecast.setWeather(forecast.weather.forecast.short);
  let skyImage = document.querySelector('#sky-layer').src = sky;

  let lottiePlayer = document.querySelector('#lottie');
  let lottieWeather1 = document.querySelector('#lottie-weather1');
  let lottieWeather2 = document.querySelector('#lottie-weather2');

  // Type of house to load
  if(forecast.weather.forecast.temp <= 65) {
    lottiePlayer.load('/lottie/_MCF-House-Cool.json');
  } else {
    lottiePlayer.load('/lottie/_MCF-House-Heat.json');
  }

  // Load rain
  if (lottieWeather.rain.keywords.some(v => forecast.weather.forecast.short.includes(v))) {
    lottieWeather1.load(`/lottie/${lottieWeather.rain.file}`);

    LottieInteractivity.create({
      mode: 'chain',
      player: '#lottie-weather1',
      mode:"chain",
      actions: [ { state:'loop' } ]
    });
  }

  // Load thunder
  if (lottieWeather.lightning.keywords.some(v => forecast.weather.forecast.short.includes(v))) {
    lottieWeather2.load(`/lottie/${lottieWeather.lightning.file}`);

    LottieInteractivity.create({
      mode: 'chain',
      player: '#lottie-weather2',
      mode:"chain",
      actions: [ { state:'loop' } ]
    });
  }

  LottieInteractivity.create({
    mode: 'chain',
    player: '#lottie',
    actions: [{state: 'autoplay'}]
  });

  function triggerAnimation(data) {
    console.log(data);
  }
})
